import axios from '@/axios.js'

export default {
    getWebcams: async (resortUuid, parameters) => {
        try {
            
            let params = {params:{...parameters,...{perPage:50}}}
            const response = await axios.editorapi.get(`webcams/v3/${resortUuid}`, params)            
            return response.data.object
             
        } catch (error) {
            throw new Error(error)
        }
    },
    createWebcam: async (resortUuid, payload) => {
        try {
            const response = await axios.editorapi.post(`webcams/v3/${resortUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    saveWebcam: async (resortUuid, webcamUuid, payload) => {
        try {
            const response = await axios.editorapi.patch(`webcams/v3/${resortUuid}/${webcamUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    deleteWebcam: async (resortUuid, webcamUuid) => {
        try {
            const response = await axios.editorapi.delete(`webcams/v3/${resortUuid}/${webcamUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    sortWebcams: async (resortUuid, webcamsOrdered) => {
        try {
            const response = await axios.editorapi.patch(`webcams/v3/${resortUuid}`, { 'sorted_webcams': webcamsOrdered })
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    }
}